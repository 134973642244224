body {
  background: #c0c7d1;
  height: 100vh;
}

/* LINKS */

a {
  outline: none;
  text-decoration: none;
  color: #2e333a;
}
a:visited {
  color: #2e333a;
}
a:hover {
  color: #ffffff;
  text-shadow: 0px 0px 15px #ffffff;
}

/* CLASSES */

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 30px;
}

.links {
  width: 300px;
  color: #2e333a;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

/* COLORS */

.cyan {
  color: #00ffb7;
}

.darkgrey {
  color: #2e333a
}

.magenta {
  color: #ff00aa;
}

.white {
  color: #ffffff
}
